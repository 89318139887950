import React from 'react';
import { Switch, Route, HashRouter  } from "react-router-dom";
import Home from './home';
import NoMatch from './no_match';

const App = () => (
    <HashRouter>
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    </HashRouter>
);

export default App;
