import React from 'react';
import Masonry from 'react-masonry-css';
import Category from '../modules/models/category';
import CategoryComponent from './category';

const ResponsiveCategories = (props: any) => {
    return (
        <Masonry
            breakpointCols={{
                default: 4,
                1280: 3,
                1024: 2,
                768: 2,
                640: 1
            }}
            className="flex flex-wrap p-4 mx-auto lg:mt-5"
            columnClassName="w-auto">
            {
                props.cheatsheet.map((category: Category) =>{
                    if(category.title ? category.title.includes(props.res) : 0) return <CategoryComponent key={category.title} category={category} />
                    else return null;
                })
            }
        </Masonry>
    );
}

export default ResponsiveCategories;
