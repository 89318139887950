import React, { useState, useEffect } from 'react';

import Category from '../modules/models/category';
import Subcategory from '../modules/models/subcategory';

import { trackView, trackSearch } from '../utils/googleAnalytics';

import SearchBar from '../components/searchBar';
import Categories from '../components/categories';

import ResponsiveCategories from '../components/responsiveCategories'

import Footer from '../components/footer';

const Home = (props: any) => {
    const json: Category[] = require('../modules/cheatsheet.json');
    const [cheatsheet, setCheatsheet] = useState<Category[]>(json);

    useEffect(() => {
        trackView('/cheatsheet');
    }, []);

    const search = (event: any) => {
        const text: string = event.target.value;

        let newCheatsheet: Category[] = json.map((category: Category) => {
            return {
                ...category,
                'content': category.content.map((subcategory: Subcategory) => {
                    console.log(subcategory)
                    return {
                        ...subcategory,
                        'table': (subcategory.title ? subcategory.title.includes(text) : 0 || subcategory.description? subcategory.description.includes(text) : 0) ? subcategory.table : subcategory.table.filter(tr => {
                            let isValid = false;

                            tr.forEach(td => {
                                if (!isValid) {
                                    isValid = td.includes(text);
                                }
                            });

                            return isValid;
                        }),
                    };
                }),
            };
        });

        setCheatsheet(newCheatsheet);
        trackSearch(text);
    };

    return (
        <>
            <SearchBar search={search} />
            <Categories cheatsheet={cheatsheet} />
            <ResponsiveCategories cheatsheet={cheatsheet} res="XL"/>
            <ResponsiveCategories cheatsheet={cheatsheet} res="MD"/>
            <ResponsiveCategories cheatsheet={cheatsheet} res="LG"/>
            <ResponsiveCategories cheatsheet={cheatsheet} res="SM"/>
            <Footer />
        </>
    );
}

export default Home;
